import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ExportTestCase = () => {
  return (
    <LayoutComponent>
      <h2>Export Test Cases</h2>

      <p>
        You can easily export test cases from the GAINSS plugin to Comma-Separated Values (CSV) files.
      </p>
      <p>
        This feature allows you to export selected test cases or all test cases in a structured format for reporting or further analysis.
      </p>

      <div className="testimonials">
        <h5>Tip</h5>
        <p>
          After exporting your test cases to CSV, you can open them in spreadsheet applications like Excel or Numbers for easy viewing and manipulation.
        </p>
      </div>

      <h5>Exporting Your Data from GAINSS</h5>

      <ol>
        <li>
          On the Test Cases main page, select the test cases you wish to export by checking the boxes next to them.
        </li>
        <li>
          Click on the <strong>More</strong> dropdown menu located at the top right corner of the test case list.
        </li>
        <li>
          Select <strong>Export to CSV</strong> from the dropdown options.
        </li>
        <li>
          If you have selected specific test cases, a popup will appear displaying the details of the selected test cases. Review the information and confirm that you want to proceed with the export.
        </li>
        <li>
          If no test cases are selected, all available test cases will be exported automatically.
        </li>
        <li>
          After confirming your export choice, the CSV file will be generated and downloaded to your local storage.
        </li>
      </ol>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          The exported CSV file will include details such as test case descriptions, objectives, pre-conditions, status, and priority fields.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default ExportTestCase
